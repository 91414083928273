/*
 * Copyright 2023-2024 Liberty Certified -- all rights reserved.
 */
@media screen and (max-width: 992px) {
  html {
    font-size: calc(0.2em + 1vw);
  }
}

/* Restrict height and align width to height */
/* !important is needed, because bootstrap classes override it to auto */
.img-component {
  max-height: 12rem;
  width: auto !important;
}
